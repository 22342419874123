import Blade from '@/components/Blade'
import Button, { BTN_COLOR } from '@/components/Button'
import Footer from '@/components/Footer'
import Header from '@/components/Header'
import Heading from '@/components/Heading/Heading'
import Link from '@/components/Link'
import { graphql, useStaticQuery } from 'gatsby'
import honeycomb from '@/images/honeycomb-light1.png'
import Lottie from 'react-lottie'
import * as hexBgWhite from '@/images/lotties/hex-bg-white.json'
// import CookieBanner from '@/globals/CookieBanner'

const NotFoundPage = ({ location }) => {
	const { configs } = useStaticQuery(
		graphql`
			query getConfig {
				configs: allDatoCmsPage {
					edges {
						node {
							id
							locale
							pageSlug
							pageName
						}
					}
				}
			}
		`
	)

	const hexBgLottieOptions = {
		loop: true,
		autoplay: true,
		animationData: hexBgWhite,
		rendererSettings: {
			preserveAspectRatio: `xMidYMid slice`,
		},
	}

	return (
		<main>
			<Header locale={configs.edges[0].node.locale} pathname={location.pathname} />
			<div className="bg-black3">
				<Blade className="clipped-faqs min-h-[88vh] flex flex-col justify-center bg-gradient-to-b from-light1 to-teal">
					<img
						src={honeycomb}
						className="w-[100vw] h-auto absolute top-48 lg:top-64 left-1/2 transform -translate-x-1/2 opacity-50 z-0"
					/>
					{/* <div className="w-full h-auto absolute top-20 lg:top-20 left-1/2 transform -translate-x-1/2 opacity-50 z-0">
						<Lottie options={hexBgLottieOptions} height={400} width={`100%`} />
					</div>
					<div className="w-full h-auto absolute top-20 lg:top-20 left-1/2 transform -translate-x-1/2 translate-y-[100%] opacity-50 z-0">
						<Lottie options={hexBgLottieOptions} height={400} width={`100%`} />
					</div> */}
					<div className="z-10">
						<Heading
							{...{
								type: 1,
								className: `mb-8`,
							}}
						>
							PAGE NOT FOUND
						</Heading>
						<Heading
							{...{
								type: 6,
							}}
						>
							You are lost in an unknown realm
						</Heading>
						<Link to={`/`} external={false}>
							<Button
								text={`Back to home`}
								containerClassName="w-fit mt-6"
								color={BTN_COLOR.MAGENTA}
								hoverColor={BTN_COLOR.DARK}
							/>
						</Link>
					</div>
				</Blade>
			</div>
			<Footer locale={configs.edges[0].node.locale} pathname={location.pathname} />
			{/* <CookieBanner /> */}
		</main>
	)
}

export default NotFoundPage
